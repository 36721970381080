import { FC } from "react";
import { Menu, Button, Text, Group, ScrollArea } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { useNavigate, useParams } from "react-router-dom";

export const JournalIndexHover: FC<{ entries: Array<any>; chapter: any }> = ({
  entries,
  chapter,
}) => {
  const navigate = useNavigate();
  const goToEntry = (entryId: any) => {
    let path = `/${chapter}/entry/${entryId}`;
    console.log(chapter);
    console.log("path", path);
    navigate(path);
  };
  const { height } = useViewportSize();

  return (
    // <div className="fixed top-15 1/4">
    <div className="">
      <Group justify="center">
        <Menu
          width={320}
          shadow="md"
          trigger="hover"
          openDelay={100}
          closeDelay={100}
        >
          <Menu.Target>
            <Button
              variant="outline"
              color="lime"
              radius="xl"
              className="font-mono"
            >
              archive
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <ScrollArea h={`calc(${height}px - 40px)`}>
              <Menu.Label>Journal Entries</Menu.Label>
              <div className="flex flex-col space-y-5">
                {entries.map((entry) =>
                  entry.id.startsWith("notes") ||
                  entry.id.startsWith("video") ? null : (
                    <Menu.Item
                      className="font-mono text-sm cursor-pointer hover:bg-blue-700 rounded-lg p-1 pl-2"
                      key={entry.title}
                      onClick={() => goToEntry(entry.id)}
                      // onClick={() => {refArray.current?.[3]?.scrollIntoView();}}
                    >
                      {entry.title}
                    </Menu.Item>
                  )
                )}
              </div>
              <Menu.Divider />
              <Menu.Label>Notes on Readings</Menu.Label>
              <div className="flex flex-col space-y-5">
                {entries.map((entry) =>
                  entry.id.startsWith("notes") ? (
                    <Menu.Item
                      className="font-mono text-sm cursor-pointer hover:bg-blue-700 rounded-lg p-1 pl-2"
                      key={entry.title}
                      onClick={() => goToEntry(entry.id)}
                      // onClick={() => {refArray.current?.[3]?.scrollIntoView();}}
                    >
                      {entry.title}
                    </Menu.Item>
                  ) : null
                )}
              </div>
            </ScrollArea>
          </Menu.Dropdown>
        </Menu>
      </Group>
    </div>
  );
};
