import { FC, useState, useEffect } from "react";
import { GiBurningBook } from "react-icons/gi";
import { GiBee, GiBurningSkull } from "react-icons/gi";
import { VscDebugConsole } from "react-icons/vsc";
import { GoShieldCheck } from "react-icons/go";
import { RiFilePaper2Line } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mantine/core";

const icons = [
  <RiFilePaper2Line className="w-10 h-10 ml-6 mb-2" />,
  <GiBee className="w-10 h-10 ml-6 mb-2" />,
  <GiBurningSkull className="w-10 h-10 ml-6 mb-2" />,
  <GoShieldCheck className="w-10 h-10 ml-6 mb-2" />,
  <VscDebugConsole className="w-10 h-10 ml-6 mb-2" />,
];

const get_random_icon = () => {
  return icons[Math.floor(Math.random() * icons.length)];
};

export const AppHeader: FC = () => {
  let { chapterId } = useParams();
  // const [icon, setIcon] = useState(get_random_icon());

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     // Change the content every 30 seconds
  //     setIcon(get_random_icon());
  //   }, 300); //30000 / 2);

  //   // Clear the interval when the component unmounts
  //   return () => clearInterval(intervalId);
  // }, []);
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center mb-10 mt-3">
      <div
        className="flex flex-row items-center justify-center"
        // onClick={() => {cursor-pointer
        //   navigate(`/`);
        // }}
      >
        <div className="flex flex-col items-center">
          <div
            className="flex flex-row items-center cursor-pointer"
            onClick={() => {
              navigate("/");
            }}
          >
            <div className="font-mono text-2xl text-center">
              Journal of Language & Being, also Within Compute
            </div>
            {/* <RiFilePaper2Line className="w-10 h-10 ml-6 mb-2" /> */}
            {/* {icon} */}
            {/* flip the  <GiBee className="w-10 h-10 ml-6 mb-2" /> icon*/}
            {/* <GiBee className="w-10 h-10 ml-6 mb-2" /> */}
          </div>
          {/* <div className="lg:absolute lg:left-32 mb-10 mr-20 mt-3 ">
          <JournalIndexHover />
        </div> */}
        </div>
      </div>
      <div className="flex flex-row items-center justify-center mt-3">
        <Button
          variant="subtle"
          color="gray"
          className="font-mono text-gray-300 text-xs"
          onClick={() => {
            navigate("/");
          }}
        >
          cover
        </Button>
        <Button
          variant="subtle"
          color="gray"
          className="font-mono text-gray-300 text-xs"
          onClick={() => {
            navigate("/chapter_zero");
          }}
        >
          .
        </Button>
        <Button
          variant="subtle"
          color="gray"
          className="font-mono text-gray-300 text-xs"
          onClick={() => {
            navigate("/chapter_one");
          }}
        >
          I
        </Button>
        <Button
          variant="subtle"
          color="gray"
          className="font-mono text-gray-300 text-xs"
          onClick={() => {
            navigate("/chapter_two");
          }}
        >
          II
        </Button>
        <Button
          variant="subtle"
          color="gray"
          className="font-mono text-gray-300 text-xs"
          onClick={() => {
            navigate("/cyberport");
          }}
        >
          cyberport
        </Button>
      </div>
    </div>
  );
};
