
import { EntryBody } from "../components/Entry/EntryBody";

export const fractal_world: { [id: string]: any } = {

    id: "fractal_world",
    title: <>The Spontaneous Fractal Theory of World</>,
    date: "Jan 2025!",

    Body: (
        <EntryBody
        paragraphs={[

<div className="font-mono">


</div>,

<div className="font-mono">
It is hard to escape the feeling that things are isomorphic. The cell, the individual, the city, the nation, the world, (the universe, the atom?) seem to share dynamics. I think Plato already posits this, with the tripartite mind & polis (?).

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Take now the world from the scientific standard view (in 2025). In scientific work notably (but also apparent in everyday experience) we describe the real world, of atoms molecules & whatnot. We know a great deal about it, whether as equation or as muscle memory. But we are also part of that same universe, so there is a shade of compression: in the mind of someone who knows the world there are structures isoorphic to that world in some weaker or stronger sense.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Take an individual that slips into some other universe or something called U*. A great deal can be said about our universe (U) just on the basis of this lonely human that lands on U*. Even if language is not understandable in U*, much can be said of the space in which the U-human dwells by the shape of the body, the muscle memory, the reactions to certain stimulous. And likewise with language, one not need to know uch about "sharks" and "chairs" to know that one will hardly found them together except in a "wreckage" or an "aquarium". One could probably draw a relative map of a country or city just by listening a speaker of an unknown language speaking at length about their world (basically an llm moment) (also this ofc assumes some notions should be shared with U*, i.e. space, this "experiment" is not too relevant anyway).

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Now a rock slips to U*. I would say the U* dwellers would have a harder time at deriving the street structure of Barcelona from a rock than from a fellow barceloneese! (Even if the rock "occupies the same space" as the catalan g.)

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
In a sense: if the U\* dweller wanted to build a simulation of our U, they would be better off sampling "cubes of space" that include U-humans for close study than sampling... interestellar void? gaseous planets? the center of a star?

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Take a fractal. Say you want to draw a given fractal, covered by a cloak. But piercing the cloak is expensive! So you could just pierce an (arbitrarly) small point and you could observe a tiny fraction of fractal but you would still get a lot of information on the whole fractal.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
If I want to minimize the information of a pattern covering a surface _but_ cover as much surface as possible, is a fractal the optimum? Because it feels like with just an arbitrarly tiny amount of information (let us say information is "surface" in this case, if we were to describe with words how to draw the fractal "it would physically take up more space") one can get to the whole thing. Here information is used veeeery loosely, almost as synonim of "a something".

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Still in the 2025-physics picture, we see than in human mind we have an isomorphism to the universe. Of course it is very local, but it seems to get more and more fractally as time goes by. If the universe is a "forming" fractal (as it were: some filament in a surface getting optimization pressure to minimize information but maximize surface coverage) we would observe exactly this: at different scales parts _grow_ similar. In the mind of a person inside their childhood room there is a structure similar to that childhood room (this is less problematic than it seems: the person would be able to _describe_ the room & a language-description is obvs similar to the physical/experienced-describee). But then inside the physical room, the mind of that person contains also many details of the structures outside the room.

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
The physical world assumption is maybe too much. But it can probably be relaxed, no need to assume a thing in itself. Let"s not be solipsistic though. Humans have a common world that builds up with language. And this world becomes _more common_ with time (i.e. globalization).

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
So I could see humans as emergent byproduct of something that is getting optimized to be a fractal, as a spontaneous process. Information could have a great deal of dealings in the matter!

</div>,

<div className="font-mono">


</div>,

<div className="font-mono">
Also a gpu with an inference-ready neural network is smaller than a human!

</div>,

      ]}
    />
  ),
};
