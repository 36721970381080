import { VideoBody } from "../components/Entry/VideoBody";

export const video_generics_are_puzzling: { [id: string]: any } = {
  id: "video_generics_are_puzzling",
  title: (
    // <a href="https://arxiv.org/pdf/2412.11318" target="_blank">
    <>Generics are puzzling. &gt;&gt;&gt;</>
    // </a>
  ),
  date: "",

  Body: (
    <VideoBody url="https://api-dump-v2.s3.eu-west-2.amazonaws.com/movie/67647dab2aac9f1416b12d67.mp4" />
  ),
};
