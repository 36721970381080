import { ReactNode, FC } from "react";

interface Props {
  url: string;
}
export const VideoBody: FC<Props> = ({ url }) => {
  return (
    <div className="flex flex-col items-center w-full text-center">
      <video className=" border rounded-md" controls>
        <source
          src={url}
          //   "https://api-dump-v2.s3.eu-west-2.amazonaws.com/movie/66017623b617b6a425b0ee6b.mp4"
          // "https://api-dump-v2.s3.eu-west-2.amazonaws.com/movie/660748c9498542e7cf29e646.mp4"
          type="video/mp4"
        />{" "}
      </video>
    </div>
  );
};
