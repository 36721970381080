import { VideoBody } from "../components/Entry/VideoBody";

export const video_sycorax: { [id: string]: any } = {
  id: "video_sycorax",
  title: <></>,
  date: "",

  Body: (
    <VideoBody url="https://api-dump-v2.s3.eu-west-2.amazonaws.com/movie/66017623b617b6a425b0ee6b.mp4" />
  ),
};
