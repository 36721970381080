import { Button } from "@mantine/core";
import { FC, ReactNode } from "react";

interface Props {
  iconLeft: ReactNode;
  iconRight: ReactNode;
  link: string;
  cybertitle: string;
}

export const TwoIconButton: FC<Props> = ({
  iconLeft,
  iconRight,
  link,
  cybertitle,
}) => {
  return (
    <div className="relative group w-full flex flex-col items-center">
      <Button
        className="w-16 md:w-24"
        fullWidth
        justify="center"
        leftSection={iconLeft}
        rightSection={iconRight}
        variant="default"
        onClick={() => window.open(link, "_blank")}
      ></Button>
      <span className="absolute left-1/2 transform -translate-x-1/2 mt-10 w-max opacity-0 transition-opacity duration-300 group-hover:opacity-100 bg-gray-800 text-white text-sm rounded-md p-2 z-10">
        {cybertitle}
      </span>
    </div>
  );
};
