import { EntryBody } from "../components/Entry/EntryBody";

export const embryonic_ideas_around_generics_ticketing_material_inferences: {
  [id: string]: any;
} = {
  id: "embryonic_ideas_around_generics_ticketing_material_inferences",
  title: <>Embryonic Ideas around Generics Ticketing Material Inferences</>,
  date: "Decc 2024",

  Body: (
    <EntryBody
      paragraphs={[
        <div className="font-mono"></div>,

        <div className="font-mono">
          [from notes] the ticket is not worth in itself but lets one do a
          thing, gives one a social status. similarly, a linguistic ticket is
          not meaningful for what is denoted or represented, but rather because
          it gives a social status to the speaker, in this case that of being
          entitled to some material inference
        </div>,

        <div className="font-mono"></div>,
        <div className="flex flex-col items-center">
          <img
            className="border"
            src="https://gus-public.s3.us-east-1.amazonaws.com/endlessjournal/notebook_destinations.webp"
            alt="notebook"
          />
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          [from notes] is the destination-from-ticket (Adt) set of alternatives
          a cluster destination in the destination-from-context (Ac)? or it
          corresponds to roughly the whole ticket-alternatives? if it corrsponds
          to one/two of Adc, then which ticket clusters give pss to the
          destination? is the generic in those ticket clusters?
        </div>,

        <div className="font-mono">
          if Adt is not in Adc's distribution, does that mean that the ticket T
          is an outlier in At (the distribution of alternatives on the ticket)?
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          [from notes] generics are slilghtly more than just ticketing an
          inference as if the generic property is not in the individual, this
          one is _negated_, as opposed to ignored or called by another term
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          ---- Some interesting summarized points from (I) ----
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          ... the meaning of a sentence (or a thought) can be characterized in
          terms of the two aspects of its assertoric use: the 'set of sufficient
          conditions' that would warrant its assertion and the 'set of necessary
          consequences' (Brandom 2000, 63)
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          The meanings of subsentential expressions are determined by the
          contributions these expressions make to the inferential roles the
          sentences containing them participate in (see Dumet, 1991; Brandom,
          1994)
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          A semantic cluster is a set of expressions or phrases that are
          mutually dependent in teh sense that the meaning of any member of the
          set is determined by its inferential links to all the others in the
          set.
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">---</div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          The semantics of generics must hence be connected to questions of the
          dynamics of information flow, issues regarding how beliefs may be
          preserved, communicated, in order to become shared, or revised by
          resolving conflicting information. (M)
        </div>,

        <div className="font-mono">
          Generic focus information answers (implicit) why-questions as central
          issue at stake. (M)
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">---</div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          In a big enough collection of generic sentences in context: we ought
          to be able to find "clusters" of uses for the generic, cases where the
          conditions and consequences follow patterns in some feature space.
          Once we define a feature space rich enough so that some patterns
          emerge, we can cluster those patterns and examine what generics _do_
          what. The effect generics have in discourse. And test which of these
          effects are unique to generics or are shared by quantified sentences
          or other syntactic structures.
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          Two ideas from inferentialism seem interesting as a basis of the
          exploration:
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          (i) The unit of meaning is the sentence. how to reconcile this with
          LMs? what does Harris say anout this? boundaries of sounds are
          top-down... 'hamsamida'!!
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          It seems that LM design is based in the components (tokens), but how
          are bigger units (sentences) processed then during training? The only
          way to process something as a sentence is to distributionally find the
          "sentence-pattern" as useful for inference. Could be interesting to
          see in a training trajectory when does the "understanding" of
          sentences become apparent, and how this relates in accuracy. Is the
          model just an overkill 2-gram model before making use of sentences?
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          Anyway, there is a clear way to get a sentence-level quantified/vector
          equivalent of the sentence: sentence embeddings (S).
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          (ii) Do away with representationalist/model-theoretic explanations. If
          we relate generic son how the world is, it is obvious language models
          are _not_ the tool: language models have no access to experience, just
          to inferential patterns between tokenized sentences. Theories of
          generics usually go for truth conditions, truth makers or explanations
          on how the distribution on the kind has to be for the generic to be
          ""true"" (quantificational accounts).
        </div>,

        <div className="font-mono">
          One example that I think may illustrate this point: striking generics.
          So on the one hand we can account that those are special because the
          properties are striking, which I guess it is a bit on what the
          property represents? Anyway, we can hypothesize that after (or before)
          a striking generic comes precautionary talk. Precautionary talk now is
          something easier to define in distributional terms, there is a clear
          set of vocabulary that marks the langauge game of giving precautions:
          "be careful with", "you should keep in mind that", etc... So now if we
          find for _what_ generics are around precautionary talk (in language
          use), we may as well get at a similar ontology of the generics in the
          locality of striking sentences. But maybe we could find that generics
          that are not minority but are striking also are entitle precautionary
          talk, or some other type. So not classify the generic in terms of how
          we adjectivize (represent?) a property as striking but rather on the
          inferences (language distribution) they co-appear with.
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          Conditions _&_ consequences matter! I think even work that talked a
          bit about the context either focused mostly in left-side context
          (normally, conditions) or the mention of consequences was very vague
          and unclear. I am thinking here maybe of F-normality as linked to
          explanations? (B) I guess those explanations are conditions? But are
          those conditions metaphysical or literal token distributions that
          co-occur? (I have to re-read nickel ngl).
        </div>,

        <div className="font-mono">
          Anyway, point being: a huge dataset of (natural!) generics in context
          will let us pay close attention to both the "before" and the "after".
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          Now, all the talk was very abstract but I can give 2 slightly more
          concrete examples (these come from preliminary experiments &/or from
          looking at the data, mind you to collect a dataset with millions of
          generics one ends up reading thousands of them!).
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          (E.i) Grouped Generics. I have observed (especially in short generics,
          3-5 words) that these sometimes appear grouped in discourse. There
          will be a string of 2-5 generics, one after the other. This seems like
          a stage-setting mechanism (Mahrad mentioned something about the
          indefinite as stage-setting in some paper but I do not have the
          notebook with that with me right now). If we gather an amount of these
          miniclusters of generics, what can we observe on text before and
          after?
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">
          (E.ii) Bi-generics (space partition). Sometimes we have a generic
          followed by "however"-sentence. Others we have a generic followed by a
          sentence that, in a asense, "validates" the generic. In the
          destination distribution, we see this because it is split in 2
          clusters. And these 2 clusters are just a small part of all the
          destinations we can get to if we generate possible (not-this-generic)
          tickets. Therefore here the generic is limiting the continuations to
          two distinct sets of sentences: those that instantiate the property
          and those that not.
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">A more concrete example of (E.ii):</div>,

        <div className="font-mono">
          Context: If we're all optimistic about the market's prospects (or at
          least act like it), doesn't everyone benefit? Who wins when we're
          scared of the future? A: Instead of stocks, let’s apply this logic to
          houses.
        </div>,

        <div className="font-mono">Ticket: Houses are expensive.</div>,

        <div className="font-mono">
          Destination: However, if I buy a house at a high price, I’m okay if I
          can sell it for more than I bought it for at some point in the future.
        </div>,

        <div className="font-mono"></div>,

        <div className="flex flex-col items-center">
          <img
            className="border md:w-2/4"
            src="https://gus-public.s3.us-east-1.amazonaws.com/endlessjournal/houses_are_expensive.webp"
            alt="plot"
          />
        </div>,

        <div className="font-mono"></div>,

        <div className="font-mono">References:</div>,

        <div className="font-mono">
          (C) Characterizing generics are material inference tickets: a
          proof-thoeretic analysis; Preston Stovall
        </div>,

        <div className="font-mono">
          (G) Generics: Inference & Accomodation; Greg Restall
        </div>,

        <div className="font-mono">
          (I) Inferentialism; Julien Murzi & Florian Steinberger
        </div>,

        <div className="font-mono">
          (H) Information Value: Measuring Utterance Predictability as Distance
          from Plausible Alternative; Mario Giulianelli, Sarenne Wallbridge,
          Raquel Fernández
        </div>,

        <div className="font-mono">
          (B) Between logic & the world; Bernhard Nickel
        </div>,
        <div className="font-mono">
          (M) Generics in information structure: exceptions versus
          counterexamples; Alice G.B. Ter Meulen
        </div>,
        <div className="font-mono">
          (S) Sentence Transformers Documentation (https://sbert.net/)
        </div>,
      ]}
    />
  ),
};
